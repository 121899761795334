@use "/src/config" as *;

.form {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  justify-content: center;
  margin-bottom: 60px;
  width: 100vw;
  max-width: 505px;
  box-sizing: border-box;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 30px;
}

.redeemContainer {
  display: flex;
  flex: 1;
  max-width: 100%;
  color: $primary-text;

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    .loginImageContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-position: $login-img-position;
      background-size: $login-img-size;
      background-repeat: no-repeat;
    }

    h1 {
      margin: 0;
      font-size: 3.5rem;
      font-weight: 600;

      &,
      & > * {
        font-family: $primaryFont;
      }

      span {
        color: $accent;
        font-family: $primaryFont;
      }
    }

    h3 {
      color: $primary-text;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.5rem;
      margin: 1rem 0;
      font-family: $login-secondaryFont;
    }

    p {
      line-height: 1.3;
    }

    .itemContainer {
      max-width: 100%;

      a {
        text-decoration: none;
      }

      button,
      a {
        margin-top: 0.5rem;
        margin-bottom: 0.25rem;
      }

      .codeResend {
        text-align: end;
        opacity: 0.5;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .icon {
      border-radius: 50%;
      box-sizing: content-box;
      padding: 1.15rem;
      transition: all 0.5s;
      background: $login-go-back-btn-color;
      color: $login-go-back-btn-text-color;

      &:hover {
        cursor: pointer;
        transform: translateX(-10px);
      }
    }

    button {
      width: 100%;

      .icon {
        &:hover {
          transform: translateX(10px);
        }
      }

      &:disabled {
        .icon {
          &:last-of-type {
            opacity: 0.5;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }
    }

    &:first-child {
      background: $primary-bg;
      flex: 5;
      flex-basis: 40%;
    }

    &:last-child {
      background: linear-gradient(
        270deg,
        $bg-blurred-gradient-start 0%,
        $bg-blurred-gradient-end 100%
      );
      background: -moz-linear-gradient(
        270deg,
        $bg-blurred-gradient-start 0%,
        $bg-blurred-gradient-end 100%
      );
      background: -webkit-gradient(
        270deg,
        $bg-blurred-gradient-start 0%,
        $bg-blurred-gradient-end 100%
      );
      backdrop-filter: blur(64px);
      flex-basis: 60%;
      justify-content: flex-start;
      padding: 1.5rem;
      line-height: 1;

      &::before {
        opacity: 0.1;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @if $bg-login-item-1-src != "" {
          background-image: url($bg-login-item-1-src);
        }
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
        z-index: -1;
      }
    }
  }
}

.tosContainer {
  & ul {
    padding: 0;
    list-style-type: none;
    & li {
      margin: 10px 0;
      text-decoration: underline;
    }
  }
}

form {
  .textInput {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $secondary-shades-100;
    color: $secondary-shades-100;
    box-sizing: border-box;
    height: 45px;
    width: 100%;
    flex-shrink: 0;
    padding: 0 0.75em;
    font-size: 0.875rem;

    &:focus {
      outline: none;
      color: $secondary-shades-100;
      background-color: #303336;
    }
  }
}

.errorMsg {
  margin-top: 1rem;
  caret-color: $error-text;
  color: $error-text;
}

/* Chrome, Safari, Edge, Opera */
form .textInput::-webkit-outer-spin-button,
form .textInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
form .textInput[type="number"] {
  -moz-appearance: textfield;
}

button.submitBtn {
  background: transparent;
  border: none;
}

.emailContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tosContainer {
  flex-direction: column;
  & .tos {
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  }
}
@media (max-width: 1327px) {
  .item .itemContainer {
    max-width: 100%;
  }
}
@media (max-width: 1014px) {
  .itemContainer {
    margin: 0.5rem 0;
  }
  .redeemContainer {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .redeemContainer {
    .item {
      &:first-child {
        flex: 10;
      }

      &:last-child {
        padding: 4.5rem;
      }
    }
  }
}

@media (min-width: 1015px) {
  .redeemContainer {
    .item {
      flex: 1 1 50%;

      &:last-child {
        justify-content: center;
      }
    }
  }
}

@media (min-width: 1328px) {
  .redeemContainer {
    .item {
      .itemContainer {
        max-width: 50%;
      }
    }
  }
}

// MAGIC LOGIN PAGE
.magicLoginWrapper {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-height: calc(100vh - 72px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .magicLoginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    h1 {
      text-transform: uppercase;
      margin: 0;
    }

    p {
      color: $secondary-shades-100;
    }
  }
}

.codeContainer {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.monospaceInput {
  input {
    font-family: monospace;
  }
}
