@use "/src/config" as *;

.drawerTitle {
  font-size: 24px;
  margin: 0px;
}

.buttonsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  gap: 12px;

  &.top {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .iconWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .walletType {
        margin-top: 7px;
        font-size: 12px;
      }
    }
  }

  .button {
    padding: 10px 24px;

    span {
      text-transform: none;
      padding: 0;
      font-size: 17px;
    }
  }
}

@media (max-width: 575px) {
  .buttonsWrapper {
    padding: 28px 0px;

    &.top {
      padding: 24px 0px 32px;
      justify-content: center;

      .button {
        padding: 0px;
      }
    }

    .button {
      width: revert;
    }
  }
}
