@use "/src/config" as *;
@use "../Marketplace/styles/collectibles";

$borderRadius: 12px;

.utilityPage {
  min-height: 100vh;
  padding: 2em 0;
  @include container;
  display: flex;
  justify-content: center;

  .loaderContainer {
    flex: 1;
  }

  .headingImage {
    position: relative;
    width: 100%;
    height: 400px;
    padding-bottom: 0;
    margin-bottom: 1.5em;

    .background,
    .nobackground {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $borderRadius;
      }
    }
    .background {
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: $borderRadius;
        background: linear-gradient(
          180deg,
          rgba(20, 20, 31, 0) 0%,
          rgba(14, 14, 18, 0.6) 100%
        );
      }
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      padding: 2em;
      color: white;

      h1 {
        font-size: 2.5rem;
        margin-bottom: 0;
      }

      p {
        margin: 0.4em 0;
        margin-bottom: 2em;
      }

      a {
        text-decoration: none;
        font-weight: 600;
        padding: 0.5em 2.5em;
        border-radius: 8px;
        background: #ffffff;
        color: #000000;
      }
    }
  }

  .description {
    margin: 1em 0 4em 0;
    opacity: 0.7;
    line-height: 28px;
  }

  .sectionContainer {
    margin-bottom: 3em;
    border-bottom: 1px solid rgba($primary-text, 0.2);

    &:last-child {
      border-bottom: unset;
    }
  }

  .sectionTitle {
    margin: 0;
    margin-bottom: 1em;
    font-size: 1.2rem;

    a {
      margin-left: 0.4em;
    }
  }

  .list {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0 0 3rem;
    height: auto;
    width: 100%;

    li {
      list-style: none;
      cursor: pointer;

      h3 {
        font-size: 1.1rem;
        color: $primary-text;
      }

      h4 {
        font-weight: 100;
      }
    }
  }
}

@include breakpoint(sm) {
  .sectionContainer {
    .list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include breakpoint(md) {
  .sectionContainer {
    .list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@include breakpoint(lg) {
  .sectionContainer {
    .list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include breakpoint(xl) {
  .sectionContainer {
    .list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@include breakpoint(xxl) {
  .sectionContainer {
    .list {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
