@forward "/src/config/styles" with ($accent: #D75A64 !default,
  $primary-bg: #141415 !default,
  $nav-bg: #06060a !default,
  $primary-text: #ffffff !default,
  $bg-blurred-gradient-start: #06060a !default,
  $bg-blurred-gradient-end: #06060a !default,

  $primary-50: rgba(188, 43, 68, 0.5) !default,

  $secondary-shades-100: #d2d2db !default,
  $secondary-shades-500: #2e2e38 !default,
  $secondary-shades-700: #21212c !default,
  $secondary-shades-900: #14141f !default,

  $nav-height: 72px !default,
  $form-margin: 2em !default,
  $error-text: #de1347 !default,
  $warning-text: #fb8c00 !default,
  $success-text: #22bb33 !default,
  $info-text: #5bc0de !default,
  $default-alert-text: #aaaaaa !default,
  $modal-scrim: #212121 !default,
  $state-bg-color: #1f5a94 !default,
  /* the  color background of state (ex: input focuse, modal overlay ect)*/
  $light-gray: #8c8f97 !default,
  $bg-blurred-filter: #ffffff !default,
  $active-state: #07d38a !default,
  $login-img-size: cover !default);